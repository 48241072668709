import coreight1 from '../../assets/code/Accueil-connecte-–-1@2x-scaled.jpg'
import carcelle from '../../assets/code/FireShot-Capture-005-Carcelle-www.carcelle.com_-scaled-e1600002854587.jpg'
import taxiech from '../../assets/code/Taxi-Gironde-33_accueil-scaled-e1615300559296.jpg'
import destmaroc from '../../assets/code/Web-1920-–-2@2x-1-scaled.jpg'
import lpgl2 from '../../assets/code/leptitgamelab2.png'
import alw1 from '../../assets/code/apprendreleweb1.png'
import CardProject from '../../components/CardProjects'
import "./CodeProjects.css"


export default function CodeProjects() {
    return (
        <div className="projects_code-global-container">
            <CardProject available={true} img={alw1} title='Apprendre Le Web' link='https://apprendreleweb.ayoub-echcharrat.fr/' content="La création de l'application web Apprendre Le Web a été un projet ambitieux visant à fournir aux utilisateurs une plateforme informative et éducative pour démystifier les concepts du web. Ce site d'articles vise à rendre les sujets complexes du web plus accessibles et compréhensibles pour un public varié." stacks={['Next.Js','Redux','TypeScript','HTML','CSS']} />
            <CardProject available={true} img={lpgl2} title='Le Petit GameLab' link='https://ayoubechcharrat.github.io/LePetitGameLab/' content="La création de l'application web Le Petit GameLab a été une aventure passionnante dans le monde du développement. Cette application React offre aux utilisateurs la possibilité de jouer à deux jeux différents directement depuis leur navigateur." stacks={['React','Redux','HTML','CSS']} />
            <CardProject available={false} img={destmaroc} title='Destination Maroc' link='nolink' content="La conception d'un site web fictif pour une agence de voyage. Le site a été conçu pour offrir aux voyageurs une vitrine en ligne mettant en avant les destinations exceptionnelles et les expériences uniques que le Maroc a à offrir. Le site se concentrait principalement sur la présentation d'informations et d'images pour inspirer les visiteurs." stacks={['Next.Js','TypeScript','Redux','HTML','SASS']} />
            <CardProject available={false} img={coreight1} title='Coreight' link='nolink' content=" J'ai eu l'occasion de développer un site web nommé Coreight, une plateforme fictive dédiée au téléchargement de polices d'écriture. Ce projet a été entièrement conçu en utilisant JavaScript vanilla. Seulement quelques polices étaient alors disponibles, toutes sous licence libre d'utilisation et récupérées sur dafont.com." stacks={['HTML','CSS', 'JavaScript']} />
            <CardProject available={false} img={carcelle} title='Carcelle' link='nolink' content="La création du site Carcelle pour un concessionnaire automobile  a été une tâche stimulante et enrichissante, qui a permis de répondre aux besoins d'une entreprise proposant des véhicules d'Allemagne vers la France. Le site a été conçu pour jouer le rôle de vitrine, offrant une expérience utilisateur exceptionnelle en présentant une liste régulièrement mise à jour des véhicules disponibles." stacks={['HTML','CSS','JavaScript','PHP']} />
            <CardProject available={true} img={taxiech} title='Taxi ECH' link='https://taxi-gironde-33.fr/' content="La création d'un site web pour une entreprise de taxi est une étape cruciale dans l'ère numérique d'aujourd'hui. Le site sert à la fois de vitrine en ligne et de plateforme de réservation pratique, facilitant la vie des clients et optimisant la gestion de l'entreprise." stacks={['HTML','CSS','JavaScript','WordPress']} />
        </div> 
    )
}

    {/* <div className="projects_code-container height300">
                <div className="projects_images">
                    <img className='img-50' src={alw1} alt="" />
                </div>
                <div className="container-text">
                <h2 className="code-title"><Link target="_blank"  to={"https://apprendreleweb.ayoub-echcharrat.fr/"}>Apprendre Le Web</Link>  </h2>(maintenances fréquentes)
                        <p>
                        La création de l'application web Apprendre Le Web a été un projet ambitieux visant à fournir aux utilisateurs une plateforme informative et éducative pour démystifier les concepts du web. Ce site d'articles vise à rendre les sujets complexes du web plus accessibles et compréhensibles pour un public varié.
                        <br/><br/>
                        Elle utilise Next.js pour le front-end, Redux pour la gestion des données, et Node.js avec Express.js pour le backend. La base de données MongoDB stocke les articles, offrant une solution souple pour leur gestion. L'objectif principal est de rendre l'apprentissage des sujets web plus accessible à un large public.                        
                        <br/>
                        Le site est actuellement en maintenance.
                        </p>
                </div>
            </div> */}
           {/*  <div className="projects_code-container container-reverse height400">
                <div className="projects_images">
                    <img className='img-50' src={lpgl1} alt="" />
                    <img className='img-50' src={lpgl2} alt="" />
                </div>
                <div className="container-text">
                    <h2 className="code-title"><Link target="_blank"  to={"https://ayoubechcharrat.github.io/LePetitGameLab/"}>Le Petit GameLab</Link>  </h2>(disponible)
                        <p>
                        La création de l'application web Le Petit GameLab a été une aventure passionnante dans le monde du développement. Cette application React offre aux utilisateurs la possibilité de jouer à deux jeux différents directement depuis leur navigateur.
                        <br/><br/>
                        Le premier jeu, intitulé "FinDate", propose une expérience basée sur la réflexion et la culture. Les joueurs sont confrontés à une série de cinq images, chacune étant associée à un sélecteur de date. Le défi consiste à deviner la date à laquelle chaque image a été prise. Le jeu repose sur une petite base de données riche de près de 50 images. "FinDate" teste les connaissances en histoire et en culture générale, tout en offrant un divertissement stimulant.
                        <br/><br/>
                        Le second jeu de "FlaGame" est un jeu de mémoire et d'association. Les joueurs sont invités à associer le nom d'un pays au bon drapeau qui s'affiche à l'écran. Ce jeu propose quatre niveaux de difficulté, chacun modifiant le temps imparti pour effectuer chaque association. Il teste la mémoire visuelle et les connaissances géographiques des joueurs, offrant ainsi une expérience amusante et éducative.
                        </p>
                </div>
            </div> */}
           {/*  <div className="projects_code-container">
                <div className="projects_images">
                    <img className='code-img' src={coreight1} alt="" />
                    <img className='code-img' src={coreight2} alt="" />
                </div>
                <div className="container-text">
                    <h2 className="code-title">Coreight </h2>(non disponible)
                        <p>
                        J'ai eu l'occasion de développer un site web nommé "Coreight", 
                        une plateforme fictive dédiée au téléchargement de polices d'écriture.
                        Ce projet a été entièrement conçu en utilisant JavaScript vanilla.
                        Seulement quelques polices étaient alors disponibles, 
                        toutes sous licence libre d'utilisation et récupérées sur dafont.com.
                        <br/><br/>
                        L'application web proposait de faire une sélection multiple avant de télécharger
                        les polices. Le systeme de connexion n'était cependant pas mis en place.
                        <br/><br/>
                        Malheureusement, bien qu'il ait été un projet intéressant à développer, 
                        "Coreight" n'est plus accessible aujourd'hui, mais il a été une occasion 
                        d'explorer et d'améliorer mes compétences en développement web.
                        </p>
                </div>
            </div> */}
{/*             <div className="projects_code-container container-reverse">
                <div className="projects_images">
                    <img className='code-img' src={carcelle} alt="" />
                </div>
                <div className="container-text">
                    <h2 className="code-title">Carcelle </h2>(non disponible)
                        <p>
                        La création du site Carcelle pour un concessionnaire automobile  a été une tâche stimulante et enrichissante, qui a permis de répondre aux besoins d'une entreprise proposant des véhicules d'Allemagne vers la France. Le site a été conçu pour jouer le rôle de vitrine, offrant une expérience utilisateur exceptionnelle en présentant une liste régulièrement mise à jour des véhicules disponibles.
                        <br/><br/>
                        Le défi majeur de ce projet a été de créer une plateforme en ligne intuitive permettant aux visiteurs de parcourir la liste de véhicules, de filtrer les recherches selon leurs préférences, et de réserver facilement leur choix. Nous avons mis en place un système de filtres qui permet aux utilisateurs de trier les véhicules par marque, modèle, année, type de carburant et d'autres critères pertinents. Cela a grandement simplifié la recherche, aidant les clients à trouver rapidement les véhicules correspondant à leurs besoins.
                        <br/><br/>
                        De plus, nous avons intégré un système de réservation en ligne pour chaque véhicule. Les visiteurs peuvent réserver un véhicule en indiquant leur nom, leurs coordonnées et leur date de préférence. Une notification est automatiquement envoyée à l'entreprise, leur permettant de confirmer la réservation et de prendre contact avec le client pour finaliser les détails.
                        <br/><br/>
                        Le site "Carcelle" offre également une option de contact direct avec l'entreprise pour répondre aux questions des clients et fournir des informations supplémentaires sur les véhicules disponibles. Cette communication fluide a renforcé la confiance des clients et amélioré l'expérience globale.
                        </p>
                </div>
            </div> */}
{/*             <div className="projects_code-container">
                <div className="projects_images">
                    <img className='code-img' src={taxiech} alt="" />
                </div>
                <div className="container-text">
                    <h2 className="code-title"><Link target="_blank"  to={"https://taxi-gironde-33.fr/"}>Taxi ECH</Link> </h2>(disponible)
                        <p>
                        La création d'un site web pour une entreprise de taxi est une étape cruciale dans l'ère numérique d'aujourd'hui. Le site sert à la fois de vitrine en ligne et de plateforme de réservation pratique, facilitant la vie des clients et optimisant la gestion de l'entreprise.
                        <br/><br/>
                        La conception de ce site a été un projet passionnant et complexe, alliant l'esthétique, la fonctionnalité et la convivialité. La première priorité était de créer une vitrine en ligne attrayante qui reflète l'identité de l'entreprise de taxi, mettant en avant son professionnalisme, sa fiabilité et son service de qualité. Cela incluait le choix de couleurs et de polices, la sélection d'images de haute qualité et la création d'une mise en page soignée pour chaque page du site.
                        <br/><br/>
                        Cependant, le cœur de ce projet résidait dans la mise en place d'un système de réservation en ligne. Nous avons développé un module de réservation qui permet aux clients de sélectionner leur lieu de prise en charge, leur destination, la date et l'heure, ainsi que le type de véhicule souhaité. Une fois la réservation confirmée, un email de confirmation est automatiquement envoyé au client, tout en notifiant l'entreprise de taxi des détails de la réservation.
                        <br/><br/>
                        La gestion du système d'e-mailing était un élément clé pour maintenir une communication fluide et efficace entre l'entreprise et les clients. En plus de la confirmation de réservation, des rappels et des mises à jour ont également été automatisés, ce qui a grandement amélioré l'efficacité opérationnelle.
                        </p>
                </div>
            </div> */}
{/*             <div className="projects_code-container container-reverse">
                <div className="projects_images">
                    <img className='code-img' src={destmaroc} alt="" />
                </div>
                <div className="container-text">
                    <h2 className="code-title">Destination Maroc </h2>(non disponible)
                        <p>
                        La conception d'un site web fictif pour une agence de voyage nommée "Destination Maroc" (aussi fictive). Le site a été conçu pour offrir aux voyageurs une vitrine en ligne mettant en avant les destinations exceptionnelles et les expériences uniques que le Maroc a à offrir. L'une des caractéristiques importantes de ce projet était l'absence d'éléments backend, signifiant que le site se concentrait principalement sur la présentation d'informations et d'images pour inspirer les visiteurs.
                        <br/><br/>
                        Le site a été conçu pour capturer l'essence de ce pays magique, en mettant en avant des destinations emblématiques, des activités passionnantes, des offres spéciales et des témoignages de voyageurs précédents. Le design visuel a été soigneusement élaboré pour refléter la culture, l'histoire et la diversité du Maroc, avec une palette de couleurs vibrantes, des images saisissantes et une mise en page fluide.
                        <br/><br/>
                        Le site n'est à ce jour plus disponible.
                        </p>
                </div>
            </div> */}