import '../homepage/Index.css'
import Menus from '../../components/Menus';

export default function Projects() {

    

    return (
        <div className="index_container">
            <div className="index-background_container">
                <div className="index-content_container full-color-delayed">
                    <Menus menu1="Accueil" link1="/" menu2="À propos" link2="/about" />
                    <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>                    
                        <p className="paragraph_about">Prenons un café et discutons !  </p>
                        <p className="paragraph_about"> Contactez moi : </p>
                        <h1 style={{fontSize:'40px',cursor:'pointer'}} onClick={() => navigator.clipboard.writeText('ayoub.ech-charrat@hotmail.fr')}>ayoub.ech-charrat@hotmail.fr</h1>
                        <h3 style={{fontSize:'12px'}}>Cliquez pour copier ! </h3>
                    </div>
                    <Menus menu1="Projets" link1="/projects"  menu2="Contact" link2="/contact" />
                </div>
            </div>
        </div>

    );
}