import { useState } from 'react';
import './Blocs.css'
import CodeProjects from './CodeProjects';

export default function Blocs() {
    const [trueForDesign,setTrueForDesign] = useState(false)
    const [trueForCode,setTrueForCode] = useState(false)
    const [clickedImage, setClickedImage] = useState('')
    let images 

    function importAll(r) {
        let images = {};
         r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
        return images
    }
    
    const imagesDesign = importAll(require.context('../../assets/design', false, /\.(png|jpe?g|svg)$/));
    const imagesCode = importAll(require.context('../../assets/code', false, /\.(png|jpe?g|svg)$/));

    const openCarousel = (e) => {
        console.log(e.target.currentSrc)
        setClickedImage(e.target.currentSrc)
    }

    const closeCarousel = () => {
        setClickedImage()
    }

    return (
        <div className="bloc_container">
            <div className="bloc-selection bloc-selection_design" onClick={() => setTrueForDesign(true) & setTrueForCode(false) } ><p> Design</p> </div>
            <div className="bloc-selection bloc-selection_code" onClick={() => setTrueForCode(true) & setTrueForDesign(false)} > <p>Code</p> </div>
            <div className="images_container">
                { trueForCode ? 
                <CodeProjects/>
                /* Object.values(imagesCode).map(index => { 
                    return <div key={index} className='images-projects_container'>
                            <img onClick={(e) => openCarousel(e)} className="images-projects" src={index} />
                    </div>
                }) */
                : null
                }
                { trueForDesign ? Object.values(imagesDesign).map(index => { return <div key={index} className='images-projects_container'><img onClick={(e) => openCarousel(e)} className="images-projects" src={index} /></div>})
                : null
                }
            </div>
            {clickedImage ? <div className='carousel' onClick={() => closeCarousel()} > <img src={clickedImage} className='carousel-image' alt="" /> </div> : null}
        </div>

    );
}