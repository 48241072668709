import { Link } from 'react-router-dom'
import './CardProjects.css'

export default function CardProject({title,content,stacks,available,img,link}){
    const StringStacks = stacks.join(', ')
    return (<div>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />
        { available ?
        <div className="card_container">
            <div className="card_dot isAvailable"></div>
            <div className="card_image_cont">
                <img className="card_image" src={img} alt="" />
            </div>
            <div className="card_info_cont" >
                <Link target="_blank" to={link} ><h3 className="card_title">{title} <span class="material-symbols-outlined">open_in_new </span> </h3>  </Link>
                <p className="card_stacks">Stacks : {StringStacks} </p>
                <p className="card_content_text">{content} </p>
            </div>
        </div>
    :
    <div className="card_container">
        <div className="card_dot isNotAvailable"></div>
        <div className="card_image_cont">
            <img className="card_image" src={img} alt="" />
        </div>
        <div className="card_info_cont" >
            <h3 className="card_title">{title}</h3>

            <p className="card_stacks">Stacks : {StringStacks} </p>
            <p className="card_content_text">{content} </p>
        </div>
    </div>
    }
    </div>
    )
}