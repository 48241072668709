import '../homepage/Index.css'
import Menus from '../../components/Menus';
import Cat from '../../assets/37a26d94200607.5e78f1dac8ac5.jpg'

export default function About() {

    

    return (
        <div className="index_container">
            <div className="index-background_container" style={{overflow:"hidden"}}>
                <div className="index-content_container full-color-delayed">
                    <Menus menu1="Accueil" link1="/" menu2="À propos" link2="" />
                    <img src={Cat} className="image-of-cat" alt="" />
                    <div className="centered-content">
                        <p className="paragraph_about">Professionnel du développement et du design web. <br/> 
                            Basé à Bordeaux, je crée des expériences en ligne en combinant habilement esthétique et fonctionnalité. <br/> <br/> 
                            Mon objectif est de transformer des concepts en sites web élégants et performants. 
                            <br/> <br/>
                            <span> Bienvenue dans mon univers.</span>
                        </p>  
                    </div>
                    
                    <Menus menu1="Projets" link1="/projects"  menu2="Contact" link2="/contact" />
                </div>
            </div>
        </div>

    );
}