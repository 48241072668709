import '../homepage/Index.css'
import Menus from '../../components/Menus';
import Blocs from './Blocs';

export default function Projects() {

    

    return (
        <div className="index_container">
            <div className="index-background_container">
                <div className="index-content_container full-color-delayed">
                    <Menus menu1="Accueil" link1="/" menu2="À propos" link2="/about" />

                    <Blocs/>
                    
                    <Menus menu1="Projets" link1=""  menu2="Contact" link2="/contact" />
                </div>
            </div>
        </div>

    );
}