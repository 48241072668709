import './Index.css'
import Menus from '../../components/Menus';

export default function Index() {
    return (
        <div className="index_container">
            <div className="index-background_container">
                <div className="index-content_container not-full-color-delayed">
                    <Menus menu1="Accueil" link1="/" menu2="À propos" link2="about" />

                    <div className="centered-content">
                        <h1>Développeur <span>&</span> Designer&nbsp;Web</h1>
                        <div className="h2_container">
                            <h2>Ayoub Ech-Charrat</h2>
                        </div>
                        
                    </div>


                    <Menus menu1="Projets" link1="projects"  menu2="Contact" link2="contact" />
                </div>
            </div>
        </div>

    );
}